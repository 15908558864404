<template>
  <v-container class="order-card">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h1 class="milk-collection bb">Input Order</h1>
      <v-btn color="info" small dense class="ma-1" to="/dairy/collect">
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
    </div>

    <Stepper v-if="orderInputs" :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" vertical ref="stepper">
     tab12: {{ tab }}
      <template v-slot:SelectFarmer>
        <v-form ref="farmerForm">
          <div class="custom-input mb-6 mt-2">
            <v-combobox
              class="form-input"
              v-model="farmer"
              :search-input.sync="search"
              :items="farmers"
              item-text="farmerSearch"
              return-object
              outlined
              label="Farmer*"
              color="#00AFA5"
              required

              style="min-width: 100px; max-width: 100%;"
              hint="*Required."
              persistent-hint
            />
          </div>
        </v-form>
      </template>

      <template v-slot:SelectAgrovet>
        <div class="custom-input mb-6 mt-2">
            <v-form ref="agrovetForm" v-model="agrovetForm">
            <v-combobox
              class="form-input"
              v-model="agrovet"
              :search-input.sync="searchAgrovet"
              :items="agrovets"
              item-text="name"
              return-object
              outlined
              label="Select Agrovet*"
              color="#00AFA5"
              required
              style="min-width: 100px; max-width: 100%;"
              hint="*Required."
              persistent-hint
            />
          </v-form>
        </div>
      </template>

      <template v-slot:InputCategory>
        <div class="custom-input mb-6 mt-2">
          <v-form ref="categoryForm">
            <v-combobox
              class="form-input"
              v-model="category"
              :search-input.sync="searchCategory"
              :items="availableCategories"
              outlined
              label="Select Category*"
              color="#00AFA5"
              required
              style="min-width: 100px; max-width: 100%;"
              hint="*Required."
              persistent-hint
            />
          </v-form>
        </div>
      </template>
    
      <template v-slot:Inputs>
        <div>
          <v-form ref="inputForm" v-model="inputForm" v-if="filteredProducts.length > 0">
            <v-card class="mb-2" outlined v-for="item in filteredProducts" :key="item.uuid">
              <v-row class="d-flex justify-start ma-0 pa-0" min-height="100%">
                <v-col cols="8" class="d-flex align-center justify-start">
                  <v-row class="d-flex flex-column align-top ml-3 justify-start">
                    <v-col class="d-flex align-center justify-start my-0 py-0">
                      <div class="my-0 py-0 text-h6 font-weight-regular product-name"> {{ item.name.replace('_', ' ') }} </div>
                    </v-col>
                    <v-col class="d-flex align-center py-0 my-0 text-field-transparent justify-start">
                      <Currency :value="item.price_per_unit" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="d-flex justify-end align-start mt-0 bg-surface-variant">
                  <v-checkbox v-model="selectedProducts" :value="item" class="mt-0 pt-0 ml-0 pl-0" color="success"></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
          <div v-else>
            <span>No Products available from the shop</span>
          </div>
        </div>
      </template>
      <template v-slot:Quantity>
        <div>
          <v-card class="mb-2" outlined v-for="item in selectedProductsQuantity" :key="item.uuid">
            <v-row class="d-flex justify-start ma-0 pa-0" min-height="100%">
              <v-col cols="8" class="d-flex align-center justify-center">
                <v-row class="d-flex flex-column align-top ml-3 justify-start">
                  <v-col class="d-flex align-center justify-start my-0 py-0">
                    <div class="my-0 py-0 text-h6 font-weight-regular product-name"> {{ item.name.replace('_', ' ') }}: </div>
                    <div class="my-0 py-0 text-h6 font-weight-regular product-name"> {{ agrovet.name.replace('_', ' ') }} </div>
                  </v-col>
                  <v-col class="d-flex align-center py-0 my-0 text-field-transparent justify-start">
                    <Currency :value="item.price_per_unit" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn plain class="red--text ml-3 text-subtitle-1" @click="removeProduct(item)">REMOVE</v-btn>
              <v-spacer></v-spacer>

              <v-btn class="mx-1 pa-0" fab color="success" plain small @click="subtractQuantity(item)">
                <v-avatar  size="37" class="border-success" style="border: solid 2px">
                  <v-icon small class="ma-0 pa-0">mdi-minus</v-icon>
                </v-avatar>
              </v-btn>
              <v-avatar  size="37" border="success lg" color="success" >
                <v-icon small class="ma-0 pa-0"> {{item.quantity}} </v-icon>
              </v-avatar>
              <v-btn class="mx-1 pa-0 border-success" fab color="success"  plain small @click="addQuantity(item)">
                <v-avatar  size="37" class="border-success" style="border: solid 2px">
                  <v-icon small class="ma-0 pa-0">mdi-plus</v-icon>
               </v-avatar>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-row>
            <v-col>
              <p>Total</p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <Currency :value="totalPrice()" />
            </v-col>    
          </v-row>
        </div>
      </template>
    </Stepper>

    <v-row justify="center" align="start" class="v-dialog">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">
            <div class="mr-2">
              <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
            </div>
            <div class="product-name">
              {{((this.agrovet || {}).name || "").replace('_', ' ')  }}
            </div>  
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list density="compact">
              <v-list-item v-for="item in selectedProductsQuantity" :key="item.uuid">
                <v-list-item-title class="product-name">{{ ((item || {}).name || "").replace('_', ' ') }}</v-list-item-title>
                <v-list-item-title>
                   <Currency :value="item.price_per_unit * item.quantity" />
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-title class="text-h6" style="opacity: 0.7"> Total </v-list-item-title>
                <v-list-item-title class="text-h6" style="opacity: 0.7"> Ksh {{ totalPrice() }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="submitInput()"
            >
              CONFIRM
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              BACK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center" align="start" class="v-dialog">
      <v-dialog
        v-model="dialog2"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">
            <div class="mr-2">
              <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
            </div>
            <div class="product-name">
              {{((CartProducts[0] || {}).shop || "").replace('_', ' ')  }}
            </div>  
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-list density="compact">
              <v-list-item v-for="item in CartProducts" :key="item.uuid">
                <v-list-item-title class="product-name">{{ ((item || {}).description || "") }}</v-list-item-title>
                <v-list-item-title>
                  <Currency :value="item.amount" />
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-title class="text-h6" style="opacity: 0.7"> Total </v-list-item-title>
                <v-list-item-title class="text-h6" style="opacity: 0.7">
                  <Currency :value="cartTotalPrice(CartProducts)" /> 
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

             <v-btn
              color="green darken-1"
              text
              @click="orderDelivered()"
              v-if="tab === 1"
            >
              ORDER DELIVERED
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="dialog2 = false"
            >
              BACK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div v-if="orderInputs" class="sticky">
      <v-btn block color="success" @click="checkPopup()">
        {{ submitButton }}
      </v-btn>
    </div>

    <div v-if="!orderInputs">
      <v-tabs v-model="tab" class="mb-2" color="success">
        <v-tab v-for="item in ['Open Orders', 'Closed Orders']" :key="item" >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-slider color="success"></v-tabs-slider>
  
      <v-tabs-items v-model="tab">
        
        <v-tab-item v-for="item in ['Open Order', 'Closed Order']" :key="item">
          <div v-if="cartStatus.length > 0"></div>
          <div v-else> No {{ item }}</div>
          <v-card class="mb-2" outlined v-for="ord in cartStatus" :key="ord.orderNo" @click="sayHello(ord)">
            <v-row class="d-flex justify-start ma-0 pa-0" min-height="100%">
              <v-col cols="8" class="d-flex align-center justify-start">
                <v-row class="d-flex flex-column align-top ml-3 justify-start">
                  <v-col class="d-flex align-center justify-start my-0 py-0">
                    <div class="my-0 py-0 text-h6 font-weight-regular product-name"> Order num: {{ ord.orderNo }} </div>
                  </v-col>
                  <v-col class="d-flex align-center py-0 my-0 text-field-transparent justify-start">
                    <p style="opacity: 0.7"> {{ ord.orderedAt }} </p>
                  </v-col>
                  <v-col class="d-flex align-center py-0 my-0 text-h6 text-field-transparent justify-start">
                    <Currency :value="ord.amount" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="d-flex justify-end align-start mt-0 pt-0 bg-surface-variant">
                <div class="px-2 order-status" color="#abcfff" style="background-color: #abcfff; border-top-left-radius: 60%; border-top-right-radius: 60%;">
                  <div class="primary--text paced-status" style="opacity: 1.0 !important;">{{ tab === 1 ? "Delivered" : "Placed"}}</div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </div>

    <div class="sticky" v-if="!orderInputs">
      <v-btn class="mt-5" color="success" block @click="alternateInputOrder()">{{ orderInputs ? "Show Orders" : "Order Inputs"}}</v-btn>
    </div>



  </v-container>

</template>

<script>
import lookups from "@/mixins/lookups";
import Stepper from "@/components/Stepper";
import Currency from '@/components/Currency';

export default {
  components: { Stepper,  Currency},

  data() {
    return {
      search: null,
      searchAgrovet: null,
      searchCategory: null,
      farmers: [],
      farmer: null,
      uuid: null,
      agrovets: [],
      agrovet: null,
      categories: [],
      category: null,
      products: [],
      selectedProducts: [],
      selectedProductsQuantity: [],
      dialog: false,
      dialog2: false,
      submitButton: "NEXT",
      tabs: ["SelectFarmer", "SelectAgrovet", "InputCategory", "Inputs", "Quantity"],
      captions: {SelectFarmer: "Select Farmer", SelectAgrovet: "Select Agrovet", InputCategory: "Input Category", Quantity: "Quantity"},
      validity: {SelectFarmer: false, SelectAgrovet: false, InputCategory: false, Inputs: false, Quantity: false},
      farmerForme: false,
      agrovetForm: false,
      inputForm: false,
      orderInputs: false,
      allOrders: {},
      selectedOrder: null,
      CartProducts: [],
      tab: null,
    }
  },

  computed: {
    categoryProducts() {
      if (this.agrovet) {
        return ((this.products || {})[this.agrovet.name])
      } else {
        return []
      }
    },

    // Extract unique categories from products
    availableCategories() {

      const products = this.categoryProducts
      if (!products || !Array.isArray(products)) return []
      const categories = [...new Set(products.map(product => product.category))]
      console.log('Available categories:', categories)
      return categories 
    },

    // Filter products based on selected category
    filteredProducts() {

      const products = this.categoryProducts
      if (!products || !this.category) return products || []

      return products.filter(product => product.category === this.category)
    },

    openOrders() {
      let _this = this
      let openCart = Object.entries((this.allOrders.open_orders || {}))
      let listCartNumber = openCart.map(function(item) {
          let created_list = {}
          created_list["amount"] = _this.cartTotalPrice(item[1])
          created_list["orderNo"] = item[0]
          created_list["status"] = "placed"
          created_list.orderedAt = item[1][0].created_at
          return created_list
      })
      return listCartNumber
    },

    closedOrders() {
      let _this = this
      let closedCart = Object.entries((this.allOrders.closed_orders || {}))
      let listCartNumber = closedCart.map(function(item) {
          let created_list = {}
          created_list["amount"] = _this.cartTotalPrice(item[1])
          created_list["orderNo"] = item[0]
          created_list["status"] = "closed"
          created_list.orderedAt = item[1][0].created_at
          return created_list
      })
      return listCartNumber
    },

    cartStatus() {
      let order = this.tab === 0 ? this.openOrders : this.closedOrders
      return order
    },

    disabled() { 
      let disabled_list  = ["SelectAgrovet", "InputCategory", "Inputs", "Quantity"]
      if (this.farmer) {
        disabled_list = ["InputCategory", "Inputs", "Quantity"]
        console.log(" check if its valid farmerForm: ", this.$refs.farmerForm.validate())
        this.validity["SelectFarmer"] = this.$refs.farmerForm.validate()
      }
      if (this.agrovet) {
        disabled_list = ["Quantity"]
        this.validity["SelectAgrovet"] = this.$refs.agrovetForm.validate()
      }
      if (this.selectedProducts.length > 0) {
        disabled_list = []
      }
      return disabled_list; 
    },

    isOnline() { return this.$store.getters.networkOnline; },

    complete() {
      // console.log(" check if its valid: ", this.$refs.FarmerForm.validate())
      return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]);
    },
    cachedFarmers() { return this.$store.getters.customers }

  },

  methods: {
    async retrieveFarmers () {
      // this.searchOffLine()
      this.farmers = this.cachedFarmers
      this.farmers = this.farmers.filter(e => ((e.farmerSearch || '').toLowerCase()).match(this.search.toLowerCase()))
      if(this.isOnline) {
        this.remoteLookup('searchDairyFarmers', { search: this.search }).then( results => this.farmers = (this.farmers || []).concat(results) )
      }
    },

    // searchOffLine() {
    //   this.filteredOffline = this.$store.getters.customers
    //   this.filteredOffline = this.filteredOffline.map(item => ({...item, search: (item.name + '/' + item.registrationNumber)}))
    //   // this.filteredOffline = this.filteredOffline.filter(e => (e.name || '').toLowerCase().match(this.search.toLowerCase()) != null || (e.registration_number || '').match(this.search) != null || (e.msisdn || '').match(this.search) != null)
    //   this.filteredOffline = this.filteredOffline.filter(e => ((e.search || "").toLowerCase()).match((this.search || "").toLowerCase()))

    // },

    submitInput() {
      let shop = this.agrovet
      let ordered_products = this.selectedProductsQuantity
      let input_orders = {
        customer_uuid: this.farmer.uuid,
        agrovet: shop,
        input_orders: ordered_products
      }
      this.$store.dispatch('dairyKeInputOrders', input_orders);
      this.farmer = null
      this.agrovet = null
      this.selectedProducts = [],
      this.selectedProductsQuantity = []
      this.dialog = false
      this.orderInputs = false
      this.validity["SelectFarmer"] = false
      this.validity["SelectAgrovet"] = false
      this.validity["Inputs"] = false
      this.validity["Quantity"] = false
      this.remoteLookup('get_ordered_collections', {}).then( result => {
        this.allOrders = result
      })
    },

    orderDelivered() {
        this.remoteLookup('dairyKeOrderDelivered', {invoiceNumber: this.selectedOrder.orderNo}).then( result => {
        this.allOrders = result
      })
      this.dialog2 = false
    },

    alternateInputOrder() {
      this.orderInputs = !this.orderInputs
    },

    fieldAgentInfo() {
      let depotId = this.$store.getters.settings.fieldAgentDepotId
      let depotName = (this.$store.getters.settings.depots.filter(e => e.id==depotId )[0] || {}).name
      let fieldAgentPayload = this.$store.getters.settings.field_agent_payload
      let route = fieldAgentPayload.route
      this.collectorSettings = {route: route, processor: depotName }
    },

    addQuantity(item) {
      this.selectedProductsQuantity = this.selectedProductsQuantity.map(function(product) {
        let quantity = item.quantity || 1
        if(product.uuid === item.uuid) {
          return ({...product, quantity: quantity + 1})
        } else {
          return product
        }
      })
    },

    subtractQuantity(item) {
      this.selectedProductsQuantity = this.selectedProductsQuantity.map(function(product) {
        let quantity = item.quantity || 1
        if(product.uuid === item.uuid) {
          return ({...product, quantity: quantity - 1})
        } else {
          return product
        }
      })
    },

    totalPrice() {
      let sum = 0
      if(this.selectedProductsQuantity.length > 0) {
        this.selectedProductsQuantity.forEach(item => {
          sum += parseFloat(item.price_per_unit) * item.quantity
        })
      }
      return sum
    },

    cartTotalPrice(cartItems) {
      let sum = 0
      if(cartItems.length > 0) {
        cartItems.forEach(item => {
          sum += parseFloat(item.amount)
        })
      }
      return sum
    },

    stepChange(to) {
      this.firstStep = (to==='first');
      this.lastStep = (to==='last');
      if (this.lastStep && this.selectedProductsQuantity.length > 0) {
        this.submitButton = "SUBMIT"
        this.validity["Quantity"] = true
      } else {
        this.submitButton = "NEXT"
      }
    },

    checkPopup() {
      if(this.submitButton === "SUBMIT") {
        this.dialog = true
      } else{
        this.$refs.stepper.nextStep();
      }
    },

    removeProduct(item) {
      this.selectedProductsQuantity = this.selectedProductsQuantity.filter(e => e.uuid !== item.uuid)
      this.selectedProducts = this.selectedProducts.filter(e => e.uuid !== item.uuid)
    },

    sayHello(obj) {
      console.log("cart selected: ", obj)
      this.selectedOrder = obj
      this.dialog2 = true
    },
  },

  watch: {

    search(to) {
      this.retrieveFarmers();
    },

    farmer(to) {
      if (to && typeof(to)==='object') {
        this.uuid = to.uuid
        this.validity["SelectFarmer"] = this.$refs.farmerForm.validate()
      }
    },
    
    selectedProducts(to) {
      console.log("product selected: ", to)
      this.selectedProductsQuantity = to.map(function(e) {
        if (e.quantity === undefined) {
          return ({...e, quantity: 1})
        }else {
          return e
        }
      })
      if (to.length > 0) {
        this.validity["Inputs"] = true
      }

    },

    category(to) {
      if(to) {
        this.validity["InputCategory"] = true
      }
    },

    agrovet(to) {
      if (to) {
        this.validity["SelectAgrovet"] = true
      }
    },

    selectedOrder(to) {
      if (to.status !== undefined) {
        console.log("to: ", to)
        let category = to.status === "closed" ? "closed_orders" : "open_orders"
        this.CartProducts = this.allOrders[category][this.selectedOrder.orderNo]
        console.log("cartProducts: ", this.CartProducts)
      }
    },

    selectedProductsQuantity(to) {
      console.log("selected products with price: ", to)
    },
    
    uuid: {
      immediate: true,
      handler(to) {
        this.$store.dispatch('addCustomersToCache', [to]);
      },
    },

    // Update categories whenever products change
    products: {
      immediate: true,
      handler(to) {
        this.categories = this.availableCategories
      }
    },

  },

  mounted() {
    this.remoteLookup('dairy_ke_get_agrovet', {}).then( results => {
      this.agrovets = results.agrovet_shops
      this.products = results.agrovet_skus
    } )

    this.remoteLookup('get_ordered_collections', {}).then( result => {
      this.allOrders = result
    })

  },

  mixins: [lookups],
}
</script>

<style scoped>
  .product-name {
    text-transform: capitalize
  }
  .v-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .order-status .paced-status{
    opacity: 1.0 !important;
  }
  .order-card {
    height: 80vh;
    position: relative;
    overflow: auto;
  }
  .sticky {
    bottom: 5px;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
  }
</style>